import React, { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useId } from 'react-id-generator';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import Newsletter from 'components/Newsletter';
import { bodyAdapter } from 'utils/bodyAdapter';
import BodyRenderer from 'utils/bodyRenderer';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { NewsletterPageProps } from './models';

const NewsletterPage: FC<NewsletterPageProps> = ({
  data: {
    newsletterPage: { seo, langProps, banner, body },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);
  const adaptedBody = bodyAdapter(body);

  return (
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }}>
      {banner ? <Banner {...banner} mode={BannerMode.article} /> : null}
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_GOOGLE_CAPTCHA_PUBLIC}
        scriptProps={{ async: true, defer: true, appendTo: 'body' }}
      >
        <Newsletter />
      </GoogleReCaptchaProvider>

      {adaptedBody.map((component) => {
        const key = useId();

        return <BodyRenderer {...component} key={key} />;
      })}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    newsletterPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      banner {
        ...BannerFragment
      }
      pageDescription {
        description
        backgroundColor
      }
      body {
        teaser {
          ...TeaserFragment
        }
        newsletterNodePicker {
          ...NewsletterBannerFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default NewsletterPage;
