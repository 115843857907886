import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Image from 'common/Image';

import { ThankYouMessageProps } from './models';

import './ThankYouMessage.scss';

export const ThankYouMessage = ({ text, title, socialLinks }: ThankYouMessageProps) => (
  <div className="thankYouMessage" data-testid="thankYou-message">
    {title ? (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <p className="thankYouMessage__title">{title}</p>
        </Col>

        <Col lg={{ span: 10, offset: 1 }}>
          <p className="thankYouMessage__text">{text}</p>
        </Col>
      </Row>
    ) : null}

    <div className="thankYouMessage__social-items">
      {socialLinks.map((socialLink) => (
        <a
          key={socialLink.name}
          className="thankYouMessage__social-item"
          href={socialLink.link}
          target="_blank"
          rel="noreferrer noopener"
        >
          <div className="thankYouMessage__image-wrapper">
            {socialLink.socialIcon?.imagePicker ? (
              <Image imageData={socialLink.socialIcon.imagePicker} alt={socialLink.iconAltText} />
            ) : null}
          </div>

          <div className="thankYouMessage__name">{socialLink.name}</div>
        </a>
      ))}
    </div>
  </div>
);

export default ThankYouMessage;
